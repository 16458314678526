<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Usuarios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Administrativo</router-link>
                  </li>
                  <li class="breadcrumb-item active">Seguridad</li>
                  <li class="breadcrumb-item active">Usuarios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.usuarios.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-usuario-export"
                        v-if="$store.getters.can('admin.usuarios.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.usuarios.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Documento<input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.n_document"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.name"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Email<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.email"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Telefono</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.status"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Empresa
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.empresas"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="empresa in listasForms.empresas"
                            :key="empresa.id"
                            :value="empresa.id"
                          >
                            {{ empresa.razon_social }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in usuarios.data" :key="item.id">
                      <td>{{ item.n_document }}</td>
                      <td class="text-nowrap">{{ item.name }}</td>
                      <td>{{ item.email }}</td>
                      <td>{{ item.cel }}</td>
                      <td>
                        <span
                          class="badge"
                          data-toggle="modal"
                          data-target="#modal-form-estado"
                          style="cursor: pointer"
                          v-bind:class="[
                            item.status == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                          @click="llenarModal(item.id, item.status)"
                        >
                          {{ item.status == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td v-if="item.empresas.length > 0">
                        {{ item.empresas[0].razon_social }}
                      </td>
                      <td v-else></td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.usuarios.show')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.usuarios.delete')"
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="usuarios.total">
                  <p>
                    Mostrando del <b>{{ usuarios.from }}</b> al
                    <b>{{ usuarios.to }}</b> de un total:
                    <b>{{ usuarios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="usuarios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <UsuarioEstado ref="UsuarioEstado" />
      </div>
    </div>
    <UsuarioExport ref="UsuarioExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import UsuarioEstado from "./UsuarioEstado";
import UsuarioExport from "./UsuarioExport";

export default {
  name: "UsuarioIndex",
  components: {
    pagination,
    UsuarioEstado,
    UsuarioExport,
  },
  data() {
    return {
      filtros: {
        n_document: null,
        name: null,
        email: null,
        status: null,
        empresas: null,
      },
      usuarios: {},
      arregloEmpresas: [],
      listasForms: {
        estados: [],
        empresas: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/usuarios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.usuarios = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/UsuarioForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(usuario) {
      return this.$router.push({
        name: "/Admin/UsuarioForm",
        params: { accion: "Editar", data_edit: usuario, id: usuario.id },
      });
    },

    pivotTable() {
      return this.$router.push({
        name: "/Admin/UsuarioPivotTable",
      });
    },
    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },
    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.usuarios.show")) {
        this.$refs.UsuarioEstado.llenar_modal(id, estado);
      }
    },

    destroy(idUser) {
      this.$swal({
        title: "Esta seguro de eliminar este Usuario?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/admin/usuarios/" + idUser).then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el usuario exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getEmpresas();
  },
};
</script>
